;(function () {
	'use strict';

	// lazy load hubspot forms
	ready(function() {

		var loadForms = function() {
			var forms = document.querySelectorAll('.js-load-hubspot-form');
			if (forms.length > 0) {
				Array.prototype.forEach.call(forms, function (form) {
					if (!form.hasAttribute('data-hubspot-form-loaded') && isVisible(form)) {

						// load dependencies
						loadjs([form.getAttribute('data-jquery'), form.getAttribute('data-hubspot')], function() {

							// initialise form
                            hbspt.forms.create({
                                region: "na1",
                                portalId: form.getAttribute('data-account'),
                                formId: form.getAttribute('data-form'),
                                target: "#" + form.getAttribute('data-target'),
                                cssClass: 'js-no-validation',
                                errorMessageClass: 'message error',
                                submitButtonClass: 'Button action',
                                onFormReady: function($form) {
                                    // hide loading message
                                    if (form.hasAttribute('data-message') && form.getAttribute('data-message')) {
                                        var message = document.getElementById(form.getAttribute('data-message'));
                                        if (typeof(message) !== 'undefined' && message !== null) {
                                            message.style.display = 'none';
                                        }
                                    }
                                }
                            });
						});

						// set loaded flag
			    		form.setAttribute('data-hubspot-form-loaded', 'true');
						form.classList.remove('js-load-hubspot-form');
			        }
				});
			}
	    };

		// check on scroll, resize and orientation change
		window.addEventListener('scroll', throttle(function (event) {
		    loadForms();
		}, 200));
		window.addEventListener("resize", throttle(function (event) {
		    loadForms();
		}, 200));
		window.addEventListener("orientationChange", throttle(function (event) {
		    loadForms();
		}, 200));
		// check on manual trigger
		window.addEventListener('triggerHubSpotForms', function (e) {
			loadForms();
		}, false);
		// check immediately
		loadForms();
	});

}());
