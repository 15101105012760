$(document).ready(() => {

  $(document).on('click', '.gtagbutton', function() {
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer.push({
        'event': 'education_button',
        'category': $(this).data('category'),
        'action': $(this).data('action'),
        'label': $(this).data('label'),
        'value': $(this).data('value')
      })
    }
  })

  menuResize()
  $('.accordion .item').click(function () {
    let id = $(this).attr('id')
    let currentClass = 'open'
    if (!$(this).hasClass(currentClass)) {
      $(this).addClass('open')
      $('.accordion .collapsible[data-collapsible="' + id + '"]').addClass('open')
    } else {
      $(this).removeClass('open')
      $('.accordion .collapsible[data-collapsible="' + id + '"]').removeClass('open')
    }
  })

  $(document).on('click', '.video_wrapper_modal .close-modal', function () {
    let parent = $(this).closest('.video_wrapper_modal')
    if (parent.find('.video_modal > iframe').length) {
      let iframe = parent.find('.video_modal > iframe')
      iframe.attr('src', iframe.attr('src'))
    } else if (parent.find('.video_modal > video').length) {
      parent.find('.video_modal > video')[0].pause()
    }
    parent.fadeOut()
  })

  $('.video_animation').click(function() {
    let modal = $(this).closest('.video-tile').find('.video_wrapper_modal')
    modal.fadeIn();
  })

  $('.mainNavigation .hamburger').click(function () {
    if ($('.mainNavigation').hasClass('opened')) {
      $(this).removeClass('is-active')
      $('.mainNavigation, body').removeClass('opened').addClass('closed')
      setTimeout(() => {
        $('.mainNavigation .background, .mainNavigation .menu').hide()
      }, 300)
    } else if ($('.mainNavigation').hasClass('closed')) {
      $('.mainNavigation .background, .mainNavigation .menu').show()
      $(this).addClass('is-active')
      $('.mainNavigation, body').removeClass('closed').addClass('opened')
    }
  })

  if ($('.resource-slider').length) {
    $('.resource-slider').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1
    })
  }
})

$(window).resize(() =>  {
  menuResize()
})

const menuResize = () => {
  let width = $(window).width();
  if (width > 992 && $('.mainNavigation').hasClass('mobile')) {
    $('.mainNavigation, body').removeClass('mobile opened').addClass('desktop')
    $('.mainNavigation .background').hide()
    $('.mainNavigation .menu').show()
    $('.mainNavigation .hamburger').removeClass('is-active')
  } else if (width <= 992 && $('.mainNavigation').hasClass('desktop')) {
    $('.mainNavigation, body').removeClass('desktop').addClass('mobile closed')
    $('.mainNavigation .background, .mainNavigation .menu').hide()
  }
}